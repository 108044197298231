import React from 'react'
import { NumberFormat } from '../../../../lib/App/common/ValueFormatter'
import Translate from '../../../../lib/App/common/translator/components/translate'

const getSequence = (dataArray) => {

  let sequence = []
  let minValue = dataArray[0]
  let maxValue = dataArray[dataArray.length -1]

  if (minValue === -1) {
    sequence.push("UG")
  }
  if (minValue === 0) {
    sequence.push("EG")
  }
  if (minValue >= 1) {
    sequence.push(minValue + ". OG")
  }
  if (minValue !== maxValue) {
    if (maxValue === 0) {
      sequence.push(" - EG")
    }
    if (maxValue >= 1) {
      if (minValue >= 1){
        sequence = (minValue + ". - " + maxValue + ". OG")
      } else {
        sequence.push(" - " + maxValue + ". OG")
      }
    }
  }

  return sequence
}

const getEtagenText = (etagen, etage) => {
  let finalText;

  switch(etagen) {
    case 0:
      finalText = <Translate id='BasicHighlights/etage-type/eg'>EG</Translate>
      break;
    case -1:
      finalText = <Translate id='BasicHighlights/etage-type/ug'>UG</Translate>
      break;
    default:
      finalText = <Translate id='BasicHighlights/etage-type/og' data={{ floor: etage }} />
  }

  return finalText
}

const BasicHightlights = props => {
  let translateEtageType = ' '

  const flaeche = props.wohnungsgrundriss.flaeche || ''

  const etagen = props.wohnungsgrundriss.etageNummer || []
  let etage
  if (etagen.length >= 1) {
    etage = getSequence(etagen)
  } else {
    etage = parseInt(etagen[0])
  }

  // const translateEtageType = etagen.length >= 1 && etage !== 0 && getEtagenText(etagen[0], etage)
  translateEtageType = etage


  const employees = props.konfigurationV.highlights ? props.konfigurationV.highlights['search-employee'].value +
    ' ' + props.konfigurationV.highlights['search-employee'].unit : ''

  const translateEtage = <Translate id='BasicHighlights/etage'>Etage</Translate>
  const translateFlaeche = <Translate id='BasicHighlights/flaeche'>Fläche</Translate>
  const translateEmployees = <Translate id='BasicHighlights/mitarbeiter'>Anzahl MA</Translate>
  const translateRooms = <Translate id='BasicHighlights/rooms'>Meeting/Informalroom</Translate>
  const translateRoomType = props.konfigurationV.highlights ? <Translate id={'BasicHighlights/room-type/' + props.konfigurationV.highlights.sitzungszimmer.name}>{props.konfigurationV.highlights.sitzungszimmer.name}</Translate> : ''

  return (
    <table>
      <tbody>
        <tr>
          <td>{translateEtage}</td>
          <td id='BasicHighlights/etage-type'>{translateEtageType ? translateEtageType :''}</td>
        </tr>
        <tr>
          <td>{translateFlaeche}</td>
          <td><NumberFormat value={Number(flaeche)} /> m<sup>2</sup></td>
        </tr>
      </tbody>
    </table>
  )
}

const DeskShareRatio = props => {
  const buerolayout = props.highlights ? props.highlights.buerolayout.variationCode : ''
  const deskShareRatio = props.highlights && buerolayout !== 'blnds' ? props.highlights['desk-share-ratio'].value +
    ' ' + props.highlights['desk-share-ratio'].unit : ''

  const deskShareTitle = buerolayout !== 'blnds' ? 'Desk Share Ratio' : props.highlights.buerolayout.name
  const translateDeskShareTitle = <Translate id='BasicHighlights/desk-share-ratio'>{deskShareTitle}</Translate>

  return (
    <tr>
      <td>{translateDeskShareTitle}</td>
      <td>{deskShareRatio}</td>
    </tr>
  )
}

export default BasicHightlights
